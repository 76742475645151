import { useEffect, useState } from 'react'

import { useDependencies } from '@/components/providers/DependencyProvider'

export default function useProductPrices(product: Product): { price: number | null, salePrice: number | null, isLoading: boolean } {
	const { priceFetcher } = useDependencies()
	const [isLoading, setIsLoading] = useState(false)
	const [prices, setPrices] = useState<{ price: number | null, salePrice: number | null } | null>(null)

	useEffect(() => {
		setIsLoading(true)

		priceFetcher.load({
			id: product.id,
			price: product.price,
			sale_price: product.sale_price,
		}).then(({ sale_price, price }) => {
			setPrices({
				price,
				salePrice: sale_price,
			})
		}).finally(() => {
			setIsLoading(false)
		})
	}, [product.id])

	if (prices === null) {
		return {
			price: product.price,
			salePrice: product.price === product.sale_price ? null : product.sale_price,
			isLoading,
		}
	}

	return {
		price: prices.price,
		salePrice: prices.price === prices.salePrice ? null : prices.salePrice,
		isLoading,
	}
}
