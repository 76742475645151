import { FunctionComponent } from 'react'
import Flickity, { FlickityOptions } from 'react-flickity-component'

// TODO: Determine hover and disabled states with Zach. Also how much they are offset. Maybe there are other useful defaults as well.

const Carousel: FunctionComponent<{
	className?: string,
	options: Omit<FlickityOptions, 'arrowShape'>,
}> = ({ children, className, options }) => (
	<Flickity className={className} options={{
		arrowShape: 'M34.9779 52.433L55.9763 74.0127C56.5939 74.6474 57.4174 75 58.3095 75C59.2016 75 60.025 74.6474 60.6426 74.0127C61.9465 72.6728 61.9465 70.5571 60.6426 69.2172L42.3891 50.4584C42.1146 50.1763 42.1146 49.8237 42.3891 49.5416L60.6426 30.7828C61.9465 29.4429 61.9465 27.3272 60.6426 25.9873C60.025 25.3526 59.2016 25 58.3095 25C57.4174 25 56.5939 25.3526 55.9763 25.9873L34.9779 47.567C33.674 48.9069 33.674 51.0931 34.9779 52.433Z',

		...options,
	}}>
		{ children}
	</Flickity>
)

export default Carousel
