import Link from 'next/link'
import { useCallback, useState } from 'react'

import useMoneyFormatter from '@/hooks/useMoneyFormatter'
import useProductPrices from '@/hooks/useProductPrices'
import useQuantity from '@/hooks/useQuantity'
import { calculateUrl } from '@/utils/search'

import Popup from './Popup'
import AddToCartConfirmation from './popups/AddToCartConfirmation'
import { useAuthentication } from './providers/AuthenticationProvider'
import { useCart } from './providers/CartProvider'
import { useFavorites } from './providers/FavoritesProvider'

function ProductImage({ product, href }: { product: Product, href: string }): JSX.Element {
	let altText = product.name
	let image = (product.kind === 'search' && product.image) || null

	if (image === null) {
		const bcImage = (product.kind === 'bigcommerce' && product.images.length > 0 && product.images[0]) || null
		if (bcImage !== null) {
			altText = bcImage.altText ?? altText
			image = bcImage.url
		}
	}

	if (image === null) {
		const bcImage = ((product.kind === 'simple') && product.image) || null
		if (bcImage !== null) {
			altText = bcImage.altText ?? altText
			image = bcImage.url
		}
	}

	return (
		<div className="flex justify-center items-center">
			<Link href={href}>
				<a>
					<img className="w-full h-48 object-contain object-center" alt={altText} src={image ?? '/img/Image-Coming-Soon.jpg'} />
				</a>
			</Link>
		</div>
	)
}

export default function Product({ product }: { product: Product }): JSX.Element {
	const { email } = useAuthentication()
	const { addToCart } = useCart()
	const { price, salePrice, isLoading } = useProductPrices(product)
	const money = useMoneyFormatter()
	const favorites = useFavorites()
	const { quantity, handleQuantityInput, handleQuantityPlus, handleQuantityMinus, handleQuantityBlur } = useQuantity()
	const [isPopupOpen, setIsPopupOpen] = useState(false)

	const handleClick = async () => {
		await addToCart(product, quantity)
			.then(() => setIsPopupOpen(true))
	}

	const brandHandler = useCallback(() => {
		if (product.brand !== null) {
			// TODO: Redo this for algolia
		}
	}, [])

	const link = `/p/${encodeURIComponent(product.sku)}`

	const isFavorited = favorites.isFavorited(product)

	return (
		<div className="relative p-4 shadow rounded-lg mx-auto w-full max-w-md cursor-auto sm:max-w-full">
			{isPopupOpen && (
				<Popup close={() => setIsPopupOpen(false)}>
					<AddToCartConfirmation product={{ ...product, quantity }} close={() => setIsPopupOpen(false)}/>
				</Popup>
			)}

			{ favorites.hasFavorites && isFavorited !== undefined && (
				<div className="absolute top-0 right-0 mt-4 mr-4">
					{isFavorited ? (
						<button onClick={async () => favorites.removeFavorite(product)}>
							<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<rect x=".5" y=".5" width="23" height="23" rx="11.5" fill="#e1e3e5" stroke="#E1E3E5"/>
								<path d="M11.594 8.887l.398.534.402-.531a3.524 3.524 0 012.821-1.39c1.935 0 3.285 1.33 3.285 3.163 0 .667-.286 1.418-.792 2.216-.502.793-1.194 1.59-1.941 2.336-1.112 1.111-2.292 2.065-3.11 2.725-.26.21-.485.392-.657.538a40.641 40.641 0 00-.618-.503c-.818-.659-2.02-1.627-3.149-2.756-.747-.747-1.439-1.544-1.941-2.338-.506-.8-.792-1.551-.792-2.218C5.5 8.83 6.85 7.5 8.785 7.5a3.49 3.49 0 012.809 1.387z" fill="#737a86" stroke="#737a86"/>
							</svg>
						</button>
					) : (
						<button onClick={async () => favorites.addFavorite(product)}>
							<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<rect x=".5" y=".5" width="23" height="23" rx="11.5" stroke="#E1E3E5"/>
								<path d="M11.594 8.887l.398.534.402-.531a3.524 3.524 0 012.821-1.39c1.935 0 3.285 1.33 3.285 3.163 0 .667-.286 1.418-.792 2.216-.502.793-1.194 1.59-1.941 2.336-1.112 1.111-2.292 2.065-3.11 2.725-.26.21-.485.392-.657.538a40.641 40.641 0 00-.618-.503c-.818-.659-2.02-1.627-3.149-2.756-.747-.747-1.439-1.544-1.941-2.338-.506-.8-.792-1.551-.792-2.218C5.5 8.83 6.85 7.5 8.785 7.5a3.49 3.49 0 012.809 1.387z" stroke="#363940"/>
							</svg>
						</button>
					)}
				</div>
			)}

			<div>
				<ProductImage href={link} product={product} />

				<div className="mt-4">
					{ product.brand === null ? (
						<div className="h-6"></div>
					) : (
						<Link href={`/search${calculateUrl('', null, [product.brand])}`}>
							<a onClick={brandHandler} onKeyDown={e => e.key === 'Enter' && brandHandler()} className="inline text-green-400 font-bold underline text-xs">{product.brand}</a>
						</Link>
					)}
					<div className="h-14">
						<Link href={`/p/${encodeURIComponent(product.sku)}`}>
							<a className="inline-block">
								<h4 className="font-bold text-xl line-clamp-2">{product.name}</h4>
							</a>
						</Link>
					</div>
					<Link href={`/p/${encodeURIComponent(product.sku)}`}>
						<a className="inline-block">
							<p className="text-sm font-light">SKU: {product.sku}</p>
						</a>
					</Link>
				</div>
			</div>

			{price === null ? (
				<div className="h-12 invisible" />
			) : (
				<div className="mt-5">
					<div className="flex items-center">
						{ email !== null && isLoading ? (
							<div className="bg-gray-200 animate-pulse w-20 h-7"></div>
						) : (
							<>
								{ salePrice !== null && (<p className="line-through mr-3">{money.format(price)}</p>)}
								<p className="text-xl font-extrabold">{money.format(salePrice ?? price)}</p>
							</>
						)}
					</div>
				</div>
			)}

			{product.availability === 'disabled' ? (
				<div className="mt-4">
					<Link href={link}>
						<a className="px-3 py-1.5 text-sm font-extrabold bg-gradient-to-b from-green-400 via-green-500 to-green-500 text-white rounded-full uppercase shadow-md leading-5 ml-auto mt-auto block md:px-4 md:py-2 md:text-base md:leading-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">View Product</a>
					</Link>
				</div>
			) : (
				<div className="mt-4 flex justify-between items-center">
					<div className="flex items-center">
						<div className="rounded-full bg-gray-200 border justify-center items-center inline-flex">
							<button onClick={handleQuantityMinus} className="relative pr-1 pl-1.5 focus:outline-none rounded-l-full">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-2.5 w-2.5 sm:h-3 sm:w-3" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
								</svg>
							</button>

							<input value={quantity} onBlur={handleQuantityBlur} onChange={handleQuantityInput} className={`bg-white w-8 py-px mx-0.5 text-sm text-center appearance-none ${quantity.toString().length > 3 ? 'w-14' : ''}`} type="number"></input>

							<button onClick={handleQuantityPlus} className="pl-1 pr-1.5 focus:outline-none rounded-r-full">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-2.5 w-2.5 sm:h-3 sm:w-3" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
								</svg>
							</button>
						</div>
					</div>

					<button type="button" onClick={handleClick} className="px-3 py-1.5 text-sm font-extrabold bg-gradient-to-b from-green-400 via-green-500 to-green-500 text-white rounded-full uppercase shadow-md leading-5 md:px-4 md:py-2 md:text-base md:leading-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">{product.availability === 'preorder' ? 'Pre-Order Now' : 'Add To Cart'}</button>
				</div>
			)}
		</div>
	)
}
