import useSWR from 'swr'

import { useDependencies } from '@/components/providers/DependencyProvider'
import { Brand, Image, Maybe, Money, Product, ProductAvailable, ProductPreOrder, ProductUnavailable, Reviews } from '@/types/bigcommerce'


type BigCommerceResponse = { __typename?: 'Query' } & {
	site: { __typename?: 'Site' } & {
		product?: Maybe<{
			__typename?: 'Product',
		} & {
			relatedProducts: {
				__typename?: 'RelatedProductsConnection',
			} & {
				edges?: Maybe<Array<Maybe<{
					__typename?: 'RelatedProductsEdge',
				} & {
					node: {
						__typename?: 'Product',
					} & Pick<Product, 'entityId' | 'sku' | 'name' | 'availabilityV2'> & {
						brand?: Maybe<{
							__typename?: 'Brand',
						} & Pick<Brand, 'name'>>,

						availabilityV2: ProductAvailable | ProductPreOrder | ProductUnavailable,

						defaultImage?: Maybe<{
							__typename?: 'Image',
						} & Pick<Image, 'url' | 'altText'>>,

						images: { __typename?: 'ImageConnection' } & {
							edges?: Maybe<Array<Maybe<{
								__typename?: 'ImageEdge',
							} & {
								node: {
									__typename?: 'Image',
								} & Pick<Image, 'url' | 'altText' | 'isDefault'>,
							}>>>,
						},

						reviewSummary: {
							__typename?: 'Reviews',
						} & Pick<Reviews, 'numberOfReviews' | 'summationOfRatings'>,
						prices?: Maybe<{
							__typename?: 'Prices',
						} & {
							basePrice?: Maybe<{
								__typename?: 'Money',
							} & Pick<Money, 'value'>>,
							price: {
								__typename?: 'Money',
							} & Pick<Money, 'value'>,
						}
						>,
					},
				}>>>,
			},
		}>,
	},
}

const query = `
	query relatedProductsBySku($sku: String!) {
		site {
			product(sku: $sku) {
				relatedProducts {
					edges {
						node {
							entityId

							sku
							name
							brand {
								name
							}

							availabilityV2 {
								__typename
							}

							defaultImage {
								url(width: 256, height: 256)
								altText
							}

							images {
								edges {
									node {
										url(width: 256, height: 256)
										altText
										isDefault
									}
								}
							}

							reviewSummary {
								numberOfReviews
								summationOfRatings
							}

							prices {
								basePrice {
									value
								}
				
								price {
									value
								}
							}
						}
					}
				}
			}
		}
	}
  
`

export default function useRelatedProducts(sku: string): { products: Array<BigCommerceBasicProduct> | null, loading: boolean } {
	const { bigcommerceFetcher } = useDependencies()

	const { data } = useSWR<BigCommerceResponse>(
		[query, sku],
		async (document: string, sku: string) => bigcommerceFetcher(document, { sku }),
	)

	if (data === undefined) {
		return { products: null, loading: true }
	}

	const { product } = data.site
	if (product === null || product === undefined) {
		return { products: null, loading: false }
	}

	return {
		loading: false,
		products: product.relatedProducts.edges?.map(product => {
			const basePrice = product!.node.prices?.basePrice?.value ?? null
			const price = product!.node.prices?.price.value ?? null

			let availability: BigCommerceBasicProduct['availability'] = 'available'
			if (product!.node.availabilityV2.__typename === 'ProductPreOrder') {
				availability = 'preorder'
			} else if (product!.node.availabilityV2.__typename === 'ProductUnavailable') {
				availability = 'disabled'
			}

			return {
				id: product!.node.entityId,
				brand: product!.node.brand?.name ?? null,
				name: product!.node.name,
				sku: product!.node.sku,

				availability,

				price: basePrice ?? price,
				sale_price: basePrice === price ? null : price,
				reviewCount: product!.node.reviewSummary.numberOfReviews,
				reviewTotal: product!.node.reviewSummary.summationOfRatings,

				image: product!.node.defaultImage ? {
					url: product!.node.defaultImage.url,
					altText: product!.node.defaultImage.altText,
				} : (() => {
					let image = product!.node.images.edges?.find(image => image?.node.isDefault)
					if (image === undefined) {
						image = product!.node.images.edges?.[0]
					}

					if (image === undefined) {
						return null
					}

					return {
						url: image!.node.url,
						altText: image!.node.altText,
					}
				})(),
			}
		}) ?? null,
	}
}
