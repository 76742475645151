import { ChangeEventHandler, Dispatch, FocusEventHandler, MouseEventHandler, SetStateAction, useState } from 'react'

export default function useQuantity(initialQuantity = 1): {
	quantity: number,
	setQuantity: Dispatch<SetStateAction<number>>,
	handleQuantityInput: ChangeEventHandler<HTMLInputElement>,
	handleQuantityPlus: MouseEventHandler<HTMLButtonElement>,
	handleQuantityMinus: MouseEventHandler<HTMLButtonElement>,
	handleQuantityBlur: FocusEventHandler<HTMLInputElement>,
} {
	const [quantity, setQuantity] = useState(initialQuantity)

	const handleQuantityInput: ChangeEventHandler<HTMLInputElement> = event => {
		const input = parseInt(event.target.value, 10)
		if (input > 999999) {
			setQuantity(999999)
			return
		}

		if (input < 1) {
			setQuantity(1)
			return
		}
		setQuantity(input)
	}

	const handleQuantityPlus: MouseEventHandler<HTMLButtonElement> = () => {
		if (quantity >= 999999) {
			setQuantity(999999)
			return
		}

		if (isNaN(quantity)) {
			setQuantity(1)
			return
		}

		setQuantity(quantity + 1)
	}

	const handleQuantityMinus: MouseEventHandler<HTMLButtonElement> = () => {
		if (quantity <= 1) {
			return
		}

		if (isNaN(quantity)) {
			setQuantity(1)
			return
		}

		setQuantity(quantity - 1)
	}

	const handleQuantityBlur: FocusEventHandler<HTMLInputElement> = () => {
		if (isNaN(quantity)) {
			setQuantity(1)
		}
	}

	return {
		quantity,
		setQuantity,
		handleQuantityInput,
		handleQuantityPlus,
		handleQuantityMinus,
		handleQuantityBlur,
	}
}
