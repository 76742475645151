import Link from 'next/link'
import { useEffect } from 'react'

import Carousel from '@/components/Carousel'
import LoadingIcon from '@/components/icons/Loading'
import Product from '@/components/Product'
import { useCart } from '@/components/providers/CartProvider'
import useMoneyFormatter from '@/hooks/useMoneyFormatter'
import useRelatedProducts from '@/hooks/useRelatedProducts'

// TODO: Fix multiple Popups rendering on top of each other

export default function AddToCartConfirmation({ product, close }: { product: Product & { quantity: number }, close: () => void }): JSX.Element {
	const { contents } = useCart()
	const money = useMoneyFormatter()
	const { products: relatedProducts, loading: relatedLoading } = useRelatedProducts(product.sku)

	useEffect(() => {
		if (contents === undefined || contents === null) {
			close()
		}
	}, [contents])

	if (contents === undefined || contents === null) {
		return (
			<LoadingIcon />
		)
	}

	const productInfo = contents.line_items.physical_items?.find(item => item.sku === product.sku)

	const popupData = {
		id: product.id,
		brand: product.brand,
		sku: product.sku,
		name: product.name,
		subtotal: money.format(contents.base_amount),
		totalCartItems: contents.line_items.physical_items?.reduce<number>((acc, { quantity: cur }) => (acc + cur), 0),
		image: productInfo?.image_url,
		numberAdded: product.quantity,
		totalItemCost: productInfo?.extended_sale_price,
	}

	return (
		<>
			<div className="relative shadow border w-full border-gray-200 bg-white rounded-lg p-3 z-20 sm:max-w-md sm:p-4 md:max-w-2xl lg:max-w-3xl">
				<button onClick={close} className="absolute right-0 top-0 mt-3 mr-3 md:hidden">
					<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.67343 4.98563L9.85681 0.802248C10.0287 0.630328 10.0287 0.315142 9.85681 0.143222C9.68489 -0.0286977 9.3697 -0.0286977 9.19778 0.143222L4.98575 4.3266L0.80237 0.143222C0.63045 -0.0286977 0.315264 -0.0286977 0.143344 0.143222C-0.0285756 0.315142 -0.0285756 0.630328 0.143344 0.802248L4.35538 5.01428L0.143344 9.19766C-0.0285756 9.36958 -0.0285756 9.68477 0.143344 9.85669C0.315264 10.0286 0.63045 10.0286 0.80237 9.85669L5.0144 5.64466L9.22644 9.85669C9.39836 10.0286 9.71354 10.0286 9.88546 9.85669C10.0574 9.68477 10.0574 9.36958 9.88546 9.19766L5.67343 4.98563Z" fill="#363940"/>
					</svg>
				</button>

				<div className="md:flex md:justify-between md:items-center md:space-x-12">
					<div className="flex items-center md:flex-1">
						<div>
							<svg className="h-2.5 sm:h-5 lg:h-8" width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.2434 25.861L32.6448 1.27268C33.3299 0.435308 34.5479 0.359183 35.3092 1.04431C36.1465 1.72943 36.2227 2.94743 35.5375 3.70867L13.1569 30.733C13.1569 30.733 12.624 31.4181 11.7105 31.4181C10.797 31.4181 10.1119 30.733 10.1119 30.733L0.59626 20.5323C-0.0888635 19.771 -0.241113 18.6291 0.44401 17.8679C1.20526 17.0305 2.49938 17.0305 3.26063 17.7918L10.797 25.7849C10.797 25.7849 11.1015 26.1655 11.5582 26.1655C12.015 26.1655 12.2434 25.861 12.2434 25.861Z" fill="#8DC63F"/>
							</svg>
						</div>

						<div className="flex justify-center items-center ml-1.5 sm:ml-2.5 lg:ml-4">
							<img className="mx-auto w-16 h-16 object-contain object-center sm:h-20 sm:w-20 md:w-24 md:h-24 lg:w-36 lg:h-36" alt="" src={popupData.image ?? '/img/Image-Coming-Soon.jpg'} />
						</div>

						<div className="ml-2.5 sm:ml-4">
							<p className="text-green-400 font-bold text-xs sm:text-sm lg:text-base">{popupData.numberAdded > 1 ? `${popupData.numberAdded} ` : ''}Added To Cart</p>
							<h4 className="font-bold text-sm line-clamp-2 sm:text-base lg:text-lg">{popupData.name}</h4>
							<p className="text-xs font-light sm:text-sm">SKU: {popupData.sku}</p>
							<p className="text-lg font-extrabold mt-1 lg:text-xl">{money.format(popupData.totalItemCost ?? 0)}</p>
						</div>
					</div>

					<div className="bg-gray-200 h-px -mb-px mt-3 rounded md:hidden"></div>

					<div className="flex justify-between items-center mt-3 md:shrink-0 md:flex-col md:items-end md:mt-0">
						<button onClick={close} className="hidden md:block">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.07739 7.9771L15.7708 1.28369C16.0459 1.00862 16.0459 0.504325 15.7708 0.229253C15.4957 -0.0458187 14.9914 -0.0458187 14.7164 0.229253L7.9771 6.92266L1.28369 0.229253C1.00862 -0.0458187 0.504325 -0.0458187 0.229253 0.229253C-0.0458187 0.504325 -0.0458187 1.00862 0.229253 1.28369L6.96851 8.02295L0.229253 14.7164C-0.0458187 14.9914 -0.0458187 15.4957 0.229253 15.7708C0.504325 16.0459 1.00862 16.0459 1.28369 15.7708L8.02295 9.03155L14.7622 15.7708C15.0373 16.0459 15.5416 16.0459 15.8166 15.7708C16.0917 15.4957 16.0917 14.9914 15.8166 14.7164L9.07739 7.9771Z" fill="#363940"/>
							</svg>
						</button>

						<div className="md:flex md:flex-col md:items-end md:mt-5">
							<p className="text-sm sm:text-base">Cart Subtotal <span className="block md:inline">({popupData.totalCartItems} items):</span></p>
							<p className="text-lg font-bold sm:text-xl">{popupData.subtotal}</p>
						</div>

						<Link href="/cart">
							<a className="inline-block text-xs py-1 px-2.5 bg-gradient-to-b from-green-400 via-green-500 to-green-500 text-white rounded-full uppercase font-extrabold shadow-md leading-5 sm:text-base md:text-sm md:mt-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">Go To Cart</a>
						</Link>
					</div>
				</div>

				<div className="bg-gray-200 h-px -mb-px mt-3 rounded lg:mt-6"></div>

				<div className="mt-4 lg:mt-6">
					<p className="text-lg text-center font-bold sm:text-xl">Frequently bought together</p>

					{ relatedLoading ? (
						<div className="py-4">
							<LoadingIcon />
						</div>
					) : (
						<div className="lg:px-4">
							<Carousel options={{
								cellAlign: 'left',
								contain: true,
								pageDots: false,
							}}>
								{relatedProducts?.map(product => (
									<div key={product.id} className="px-4 py-1 w-full sm:w-1/2">
										<Product product={{ ...product, kind: 'simple' }} />
									</div>
								))}
							</Carousel>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
