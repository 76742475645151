import { FunctionComponent, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useEffectOnce } from 'react-use'

const Popup: FunctionComponent<{
	close: () => void,
}> = ({ children, close }) => {
	const ref = useRef<Element | null>(null)
	const [mounted, setMounted] = useState(false)

	useEffectOnce(() => {
		ref.current = document.querySelector('#popup')
		setMounted(true)

		document.body.style.top = `-${window.scrollY}px`
		document.body.style.position = 'fixed'
		document.body.style.width = '100%'

		return () => {
			const scrollY = parseInt(document.body.style.top || '0', 10) * -1
			document.body.style.position = ''
			document.body.style.width = ''
			document.body.style.top = ''
			window.scrollTo(0, scrollY)
		}
	})

	return mounted ? createPortal(
		(
			<div className="fixed inset-0 z-50 flex justify-center items-center px-4 first:bg-gray-800 first:bg-opacity-50">
				<div onClick={close} aria-hidden="true" className="absolute inset-0 cursor-pointer"></div>
				{children}
			</div>
		),
		ref.current!,
	) : null
}

export default Popup
