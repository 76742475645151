import { SVGAttributes } from 'react'

export default function LoadingIcon({ uniqueId, ...attributes }: SVGAttributes<SVGSVGElement> &{ uniqueId?: string }): JSX.Element {
	const id = uniqueId ?? 'default'

	return (
		<>
			<style jsx>{`
				circle {
					animation-duration: 1.4s;
					animation-timing-function: ease-in-out;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
					animation-name: circle-animation;
				}

				@keyframes circle-animation {
					0%, 25% {
						stroke-dashoffset: 280;
						transform: rotate(0);
					}

					50%, 75% {
						stroke-dashoffset: 75;
						transform: rotate(45deg);
					}

					100% {
						stroke-dashoffset: 280;
						transform: rotate(360deg);
					}
				}
			`}</style>

			<svg {...attributes} className="h-12 w-12" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<mask id={`loading-mask-${id}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
					<g className="animate-spin origin-center">
						<circle className="origin-center" cx="50" cy="50" r="45" fill="transparent" stroke="black" strokeLinecap="round" strokeDasharray="283" strokeDashoffset="280" strokeWidth="10px" />
					</g>
				</mask>
				<g mask={`url(#loading-mask-${id})`}>
					<rect width="100" height="100" fill={`url(#loading-gradient-${id})`} />
				</g>
				<defs>
					<linearGradient id={`loading-gradient-${id}`} x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
						<stop className="text-green-400" stopColor="currentColor" />
						<stop className="text-green-600" stopColor="currentColor" offset="1" />
					</linearGradient>
				</defs>
			</svg>
		</>
	)
}
